<template>
  <div class="views-layout-index">
    <CommonHeader />
    <div class="main-body">
      <router-view></router-view>
      <div class="text-center" style="padding: 25px">
        <CommonFooter />
        <CustomerService />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    CommonHeader: () => import("@/components/layout/CommonHeader"),
    CommonFooter: () => import("@/components/layout/CommonFooter"),
    CustomerService: () => import("@/components/layout/CustomerService"),
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.views-layout-index {
  padding-top: 50px;
}
</style>